/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
// @import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
/* Quill */
// @import '~quill/dist/quill.snow.css';
