/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
*,
body {
    font-family: 'Poppins', sans-serif !important;
}

.bg-lime {
    background-color: #86C127 !important;
}

.bg-blue {
    background-color: #0060AA !important;
}

.text-lime {
    color: #86C127 !important;
}

.text-white {
    color: white !important;
}

.card-login {
    margin-top: 6rem !important;
    margin: 2rem;
    border-radius: 1rem;
}

.card-login-blue {}

.fuse-vertical-navigation-wrapper {
    background-color: #5036B1 !important;
}

.fuse-vertical-navigation-item-title {
    color: white !important;
}

.fuse-vertical-navigation-content-header {
    background-color: #DCDCDC;
    height: 64px;
    justify-content: center;
}

.fuse-vertical-navigation-content-header .flex {
    justify-content: center;
}

table {
    width: 100%;
}

// Hide all elements
body.barcode-scanner-active {
    visibility: hidden;
    --background: transparent;
    --ion-background-color: transparent;
}

// Show only the barcode scanner modal
.barcode-scanner-modal {
    visibility: visible;
    position: relative;
    z-index: 999999;
}

@media (prefers-color-scheme: light) {
    .barcode-scanner-modal {
        --background: transparent;
        --ion-background-color: transparent;
    }
}